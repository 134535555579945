<template>
  <van-popup v-model="popupVisible" position="bottom">
    <van-datetime-picker
      v-model="currentDate"
      type="datetime"
      title="请选择时间"
      @confirm="confirm"
      @cancel="cancel"
    />
  </van-popup>
</template>

<script>
import { Popup, DatetimePicker } from "vant";
export default {
  name:'dateTimePickerWithPopup',
  props:{
    defaultTime: {
      type: String,
      default:''
    }
  },
  components: {
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
  },
  watch:{
    defaultTime(val){
      this.currentDate = new Date(val)
    }
  },
  data(){
    return {
      currentDate: new Date(),
      popupVisible:false
    }
  },
  methods:{
    open(){
      this.popupVisible = true
    },
    close(){
      this.popupVisible = false
    },
    confirm(date){
      this.$emit('confirm',date)
    },
    cancel(){
      this.popupVisible = false
    },
  }
};
</script>

<style>
</style>