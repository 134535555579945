<template>
  <van-popup v-model="popupVisible" position="bottom">
    <van-picker
      show-toolbar
      :value-key="valueKey"
      :columns="columns"
      @cancel="cancel"
      @confirm="confirm"
    />
  </van-popup>
</template>

<script>
import { Popup, Picker } from "vant";
export default {
  name: "pickerWithPopup",
  props: {
    valueKey: {
      type: String,
      default: "",
    },
    columns: {
      type:Array,
      default(){
        return []
      }
    }
  },
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
  data() {
    return {
      popupVisible: false,
    };
  },
  methods: {
    open() {
      this.popupVisible = true;
    },
    close() {
      this.popupVisible = false;
    },
    confirm(obj, index) {
      this.$emit("confirm", obj, index);
    },
    cancel() {
      this.popupVisible = false;
    },
  },
};
</script>

<style>
</style>