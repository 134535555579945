<template>
  <div class="appoint">
    <van-form @submit="onSubmit" :show-error-message="false" label-width="60">
      <van-field
        v-model="form.itemName"
        label="预约事项"
        name="itemName"
        placeholder="请选择"
        readonly
        is-link
        @click="handleItemClick"
        :rules="[{ required: true, message: '请选择预约事项' }]"
      />
      <van-field
        v-model="form.appointmentTime"
        label="预约时间"
        name="appointmentTime"
        placeholder="请选择"
        readonly
        right-icon="calendar-o"
        @click="handleTimeClick"
        :rules="[{ required: true, message: '请选择预约时间' }]"
      />
      <van-field
        v-model="form.remark"
        name="remark"
        label="其他备注"
        type="textarea"
        maxlength="50"
        show-word-limit
        :autosize="{ maxHeight: 100, minHeight: 50 }"
        placeholder="请输入"
        :rules="[{ required: true, message: '请输入其他备注' }]"
      />
      <div class="hover-btn" @click="handleAppointClick">
        <span>我的预约</span>
        <van-icon name="arrow"></van-icon>
      </div>
      <div class="clearfix"></div>
      <div class="submit-btn">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>

    <picker-with-popup
      ref="itemPicker"
      valueKey="itemName"
      :columns="appointmentOption"
      @confirm="itemConfirm"
    ></picker-with-popup>
    <date-time-picker-with-popup
      ref="timePicker"
      @confirm="timeConfirm"
    ></date-time-picker-with-popup>
  </div>
</template>

<script>
import {
  Form,
  Field,
  Button,
  Icon, 
  Toast
} from "vant";
import { getAppointmentitemList,appointmentSubmit} from "@/api/appointment";
import { formateDate } from "@/common/utils";
import pickerWithPopup from "@/components/pickerWithPopup";
import dateTimePickerWithPopup from "@/components/dateTimePickerWithPopup";
export default {
  name: "appoint",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Icon.name]: Icon,
    pickerWithPopup,
    dateTimePickerWithPopup
  },
  data() {
    return {
      form: {
        itemName:'',
        itemId:'',
        appointmentTime:'',
        remark:''
      },
      appointmentOption:[],
    };
  },
  mounted(){
    this.getAppointmentitemList()
  },
  methods: {
    handleAppointClick(){
      this.$router.push('/my-appoint');
    },
    async getAppointmentitemList(){
      const res = await getAppointmentitemList()
      // console.log(res)
      this.appointmentOption = res.data
    },
    async onSubmit(form) {
      console.log(form);
      const params = {
        itemId:this.form.itemId,
        itemName:this.form.itemName,
        appointmentTime: this.form.appointmentTime,
        remark: this.form.remark
      }
      const res = await appointmentSubmit({
        ...params
      })
      Toast(res.msg)
      setTimeout(() => {
        this.$router.push({
          path:'/my-appoint'
        })
      }, 500);
    },
    handleItemClick(){
      this.$refs.itemPicker.open();
    },
    itemConfirm(obj, index) {
      console.log(obj, index);
      this.form.itemName = obj.itemName;
      this.form.itemId = obj.id;
      this.$refs.itemPicker.close();
    },
    handleTimeClick() {
      this.$refs.timePicker.open();
    },
    timeConfirm(date) {
      this.form.appointmentTime = formateDate(date, "YYYY-MM-DD hh:mm:ss");
      this.$refs.timePicker.close();
    },
  },
};
</script>

<style lang="scss" scoped>

.appoint {
  background: #ffffff;
  width: 100%;
  height: 100vh;
}
.hover-btn {
  width: 88px;
  height: 28px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px 1px rgba(22,91,159,0.16);
  border-radius: 20px 0px 0px 20px;
  font-size: 14px;
  color: #1989F7;
  line-height: 28px;
  text-align: center;
  float: right;
  margin: 50px 0;
}
.submit-btn {
  position: fixed;
  width: 100%;
  bottom: 50px;
  left: 0;
  padding: 0 20px;
}
.clearfix::after{
  content:'';
  display:block;
  clear:both;
}
</style>